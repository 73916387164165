import configJson from '../../config/config';
import apiFunctions from '../../helpers/ApiSerivce';
import { objQueryConversion } from '../../helpers/HelperFunctions';
import { history } from '../../helpers/History';
import {
  ILoginPayload,
  IProfilePayload,
  IResetPasswordTokenCheck,
  IResetPasswordTokenUpdate,
  ISignupPayload,
  IUserInfo,
  IAccessTokenPayload
} from '../../model/Types';
import siteUrls from '../SiteURLs';

export function logout() {
  window.open(configJson.logout)
  localStorage.clear();
  history.push('/');
}

export function login(payload: ILoginPayload) {
  return apiFunctions.post(siteUrls.login, payload).then((user: any) => {
    return user as any;
  });
}

export function signup(payload: ISignupPayload) {
  return apiFunctions.post(siteUrls.register, payload).then((user: any) => {
    return user;
  });
}

export function getAccessToken(payload: IAccessTokenPayload) {
  return apiFunctions.post(siteUrls.getAccessToken, payload).then((user: any) => {
    return user;
  });
}

export function profileUpdate(payload: IProfilePayload) {
  return apiFunctions.post(siteUrls.updateProfile, payload).then((user: any) => {
    return user;
  });
}

export function getUserInfo() {
  return apiFunctions.get(siteUrls.getUserInfo).then((user: any) => {
    return user;
  });
}
export function resetPassword(payload: IUserInfo) {
  return apiFunctions.patch(siteUrls.forgotPassword, payload).then((user: any) => {
    return user;
  });
}

export function updateNewPasswordMail(payload: IResetPasswordTokenUpdate) {
  return apiFunctions.patch(siteUrls.resetPassword, payload).then((user: any) => {
    return user;
  });
}

export function verifyPasswordToken(payload: IResetPasswordTokenCheck) {
  const queryString = objQueryConversion(payload);

  return apiFunctions
    .get(encodeURI(`${siteUrls.verifyPasswordToken}?${queryString}`))
    .then((user: any) => {
      return user;
    });
}

export function verifyAccountVerification(payload: IResetPasswordTokenCheck) {
  return apiFunctions
    .patch(siteUrls.verifyAccountEmail, payload)
    .then((user: any) => {
      return user;
    });
}


export function getRapidTestData() {
  return apiFunctions.get(siteUrls.rapidForm).then((user: any) => {
    return user;
  });
}