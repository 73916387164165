import React, { useCallback, useEffect, useState } from 'react';

// import { useFormik } from 'formik';

import { StatusCard } from '../../components';
import '../../components/styles/styles.css';
import useAuthContext from '../../context/provider/AuthContext';
import useCommonContext from '../../context/provider/CommonContext';
import { setToken } from '../../helpers/ApiSerivce';
import { history } from '../../helpers/History';
import { getUserInfo, getAccessToken } from '../../services/api/AuthAPI';
import { Layout } from '../layout/Layout';

import '../../components/styles/Login.css';


interface LoginProps { }


export const Login: React.FC<LoginProps> = () => {
  // const [rememberMe, setRememberMe] = useState<boolean>(false);
  const { authDispatch } = useAuthContext();
  const { commonDispatch } = useCommonContext();

  const [statusCard, setStatusCard] = useState({
    type: 'success',
    message: 'Please wait...',
  });

  const clearError = useCallback(() => {
    setTimeout(() => {
      setStatusCard({
        type: 'success',
        message: '',
      });
    }, 5000);
  }, []);

  async function nhsMailToken(nhsCode) {
    clearError()
    const loginResponse = await getAccessToken({ 'code': nhsCode });
    commonDispatch({ type: 'SET_LOADING' });

    if (loginResponse && loginResponse.result) {
      setStatusCard({
        type: 'success',
        message: 'You have successfully logged in. Please wait...',
      });
      setTimeout(async () => {
        authDispatch({ type: 'LOGIN' });
        setToken(loginResponse.result.token);
        const userInfo = await getUserInfo();
        if (userInfo) {
          const responseModified = { ...userInfo.result };
          console.log('responseModified', responseModified);
          localStorage.setItem('name', `${responseModified.userData.forename} ${responseModified.userData.surname ? responseModified.userData.surname : ''}`)
          localStorage.setItem('role', responseModified.userData.role === 1 ? 'admin' : 'staff')
          authDispatch({
            type: 'LOAD_USER',
            user: responseModified.userData,
          });
        }
        if (loginResponse.result.first_login) {
          history.push('/profile');
        } else {
          history.push('/dashboard');
        }
      }, 3000);
    } else {
      commonDispatch({ type: 'UNSET_LOADING' });
      setStatusCard({
        type: 'danger',
        message: 'Something went worng, Please try again!',
      });
    }
  }

  useEffect(() => {
    // window.location.href = "https://fs.nhs.net/adfs/oauth2/authorize?response_type=code&client_id=c30dca48-cd83-4259-9f83-291d2695253e&resource=https://covidappuat.hwphis.nhs.uk/oidc/sp&redirect_uri=https://covidappuat.hwphis.nhs.uk/oidc/sp&client-request-id=ca0facb9-b210-40fd-d452-008000000071&scope=user.read+openid+profile+email";
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    console.log("code...", code);
    if (code) {
      nhsMailToken(code);
    } else {
      setStatusCard({
        type: 'danger',
        message: 'Login failed, Please try again!',
      });
    }
    setTimeout(() => {
      commonDispatch({ type: 'UNSET_LOADING' });
    }, 2000);
  }, [])



  // Formik Handler

  // const [rememberMe, setRememberMe] = useState<boolean>(false);

  return (
    <div>
      <Layout>
        <h2 className="page-header">Login</h2>
        <div className='spacer-40' />
        <div className="content-card">
          <div className="content-inner-card form">
            <div className='form-container'>

              <div className="spacer-30" />
              {statusCard && statusCard.message && (
                <StatusCard
                  type={statusCard.type}
                  message={statusCard.message}
                />
              )}

            </div>
          </div>
        </div>
        <div className="h-5" />
      </Layout>
    </div>
  );
};
