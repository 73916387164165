import React, { useState, useEffect } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import { Link, useHistory } from 'react-router-dom';

import Calander from '../../assets/images/Calander.svg';
import filedownload from '../../assets/images/file_download.svg';
import searchblack from '../../assets/images/searchblack.svg';
import { Button, SelectDropdown, TextInput } from '../../components';
import useCommonContext from '../../context/provider/CommonContext';
import { debounce } from '../../helpers/HelperFunctions';
import useQuery from '../../helpers/useQuery';
import { IAdminReportData } from '../../model/Types';
import { getAdminReportData } from '../../services/api/AdminAPI';
import { Layout } from '../layout/Layout';


import '../../components/styles/AdminReport.css';

const DATE_FORMAT = 'DD/MM/YYYY';
const TIME_FORMAT = 'hh:mm A';
const FIELDS_TO_INCLUDE_IN_CSV = [
    'forename',
    'surname',
    'gender',
    'date_of_birth',
    'address',
    'postcode',
    'email',
    'mobile_no',
    'nhs',
    'ethnicity',
    'job_role',
    'employment_status',
    'sdu',
    'work_area',
    'type',
    'tested_on',
    'specimen',
    'manufacturer_name',
    'result',
    'repeat_test',
    'comments'

];

function convertArrayOfObjectsToCSV(array: any) {
    let result: any;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';

    result = '';
    result += FIELDS_TO_INCLUDE_IN_CSV.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item: any) => {
        let ctr = 0;
        FIELDS_TO_INCLUDE_IN_CSV.forEach((key) => {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];

            ctr += 1;
        });
        result += lineDelimiter;
    });

    return result;
}

function downloadCSV(array: any) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}

const Export = ({ onExport }: any) => (
    <div className='download-icon'>
        <Button
            btntype="button"
            onClick={(e: any) => onExport(e.target.value)}
            text="DOWNLOAD REPORTS"
            addClass='csv-download'
        />
        <img className='csv-absolute' src={filedownload} alt="" />

    </div>

);

const formatData = (data: Array<IAdminReportData>): Array<any> => {
    return data.map(row => ({
        id: row.id,
        name: `${row.forename || ''} ${row.surname || ''}`,
        forename: row.forename || '',
        surname: row.surname || '',
        gender: row.gender || '',
        date_of_birth: row.date_of_birth ? moment(row.date_of_birth).format(DATE_FORMAT) : '',
        address: row.address ? row.address.split('\n')[0] : '',
        postcode: row.postcode || '',
        email: row.email || '',
        mobile_no: row.mobile_no || '',
        nhs: row.nhs_number || '',
        assignment_number: row.assignment_number || '',

        ethnicity: row.ethnicity || '',
        job_role: row.job_role || '',
        employment_status: row.employment_status || '',
        sdu: row.sdu || '',
        work_area: row.work_area || '',

        type: row.type || '',
        tested_on: row.test_date ? moment(row.test_date).format(DATE_FORMAT) : '',
        time: row.test_date ? moment(row.test_date).format(TIME_FORMAT) : '',
        specimen: row.specimen_id || '',
        manufacturer_name: row.manufacturer_name || '',
        result: row.result,
        repeat_test: row.repeat_test || '',
        comments: row.comments || ''
    }));
};

const testResultOptions = [
    { value: 'All', label: 'All' },
    { value: 'Positive', label: 'Positive' },
    { value: 'Negative', label: 'Negative' },
    { value: 'Invalid', label: 'Invalid' },
];

const UserResult = (row: any) => (
    <div className="result-block">
        <span className={`${row.result?.toLowerCase()}`}>{row.result || '-'}</span>
    </div>
);

const columns: any = [
    {
        name: 'Name',
        selector: (row: any) => row.name
    },
    {
        name: 'Email address',
        selector: (row: any) => row.email,
    },
    {
        name: 'NHS number',
        selector: (row: any) => row.nhs,
    },
    {
        name: 'Tested On',
        selector: (row: any) => row.tested_on,
    },
    {
        name: 'Specimen ID',
        selector: (row: any) => row.specimen,
    },
    {
        name: 'Result',
        selector: (row: any) => row.result,
        cell: (row: any) => <UserResult {...row} />,
    },
    {
        name: '',
        cell: (row: any) => (
            <Link className='details' to={{
                pathname: "/user-profile",
                state: row
            }}>
                Details

            </Link>
        )
    }
];

const headers = ['name', 'email', 'nhs', 'tested_on', 'specimen', 'result'];

export const AdminReport: React.FC = () => {
    const { commonDispatch } = useCommonContext();
    const history = useHistory();
    const query = useQuery();

    const copyData = React.useRef<Array<any>>();
    const debounceSearch = React.useRef<(str: string) => void>();

    const resultOption = testResultOptions.find(op => op.value === query.get('filterBy'));

    const startDate = moment(query.get('fromDate'));
    const endDate = moment(query.get('toDate'));

    const [data, setData] = useState<Array<any>>([]);
    const [filterBy, setFilterBy] = useState(resultOption || testResultOptions[0]);
    const [fromDate, setFromDate] = useState(startDate.isValid() ? startDate.toDate() : null);
    const [toDate, setToDate] = useState(endDate.isValid() ? endDate.toDate() : null);
    const [searchText, setSearchText] = useState(query.get('searchText') ? query.get('searchText') : '');

    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, [data]);

    const loadReportData = async () => {
        const payload = {
            filterBy: filterBy.value !== 'All' ? filterBy.value : undefined,
            fromDate: fromDate ? fromDate.toISOString() : null,
            toDate: toDate ? toDate.toISOString() : null
        };
        const { result } = await getAdminReportData(payload);
        const formatResult = formatData(result);
        copyData.current = formatResult;
        setData(formatResult);

        commonDispatch({ type: 'UNSET_LOADING' });
    };

    const onSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const onSearch = React.useCallback((searchStr: string) => {
        if (searchStr.trim() === '') {
            setData(copyData.current);
            return;
        }

        const filteredData = copyData.current.filter((record) => {
            for (let i = 0; i < headers.length; i += 1) {
                if (record[headers[i]].search(new RegExp(searchStr.trim(), 'i')) !== -1) {
                    return true;
                }
            }

            return false;
        });

        setData(filteredData);
    }, []);

    const formQueryString = () => {
        return `?searchText=${searchText}&filterBy=${filterBy.value}&fromDate=${fromDate?.toISOString()}&toDate=${toDate?.toISOString()}`;
    };

    useEffect(() => {
        history.push(formQueryString());
        commonDispatch({ type: 'SET_LOADING' });
        loadReportData();

    }, [filterBy, fromDate, toDate]);

    useEffect(() => {
        history.push(formQueryString());
        if (debounceSearch.current) {
            debounceSearch.current(searchText);
        }
    }, [searchText]);

    React.useEffect(() => {
        debounceSearch.current = debounce(onSearch); // initialize debounce search on mount
        debounceSearch.current(searchText); // Do initial search
    }, []);

    return (
        <div>
            <Layout nav>
                <div className="card card-padding my-md-5 my-0">
                    <div className="p-md-5 p-3 login-card">
                        <h2 className='fw-bold'>Reports</h2>

                        <div className='row search-card'>
                            <div className='col-md-3'>
                                <div className='pos-relative'>

                                    <TextInput
                                        label="Search"
                                        placeholder="Search for Name"
                                        name="searchText"
                                        type="text"
                                        values={searchText}
                                        required={false}
                                        onChange={onSearchTextChange}
                                    />
                                    <div className='search-icon'>
                                        <img src={searchblack} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <p className="input-label">Filter by</p>
                                <SelectDropdown
                                    type="text"
                                    required
                                    name="filterBy"
                                    label=""
                                    placeholder=""
                                    value={filterBy.value}
                                    onChange={setFilterBy}
                                    optionsArray={testResultOptions}
                                />
                            </div>

                            <div className='col-md-3'>
                                <p className="input-label">From date</p>
                                <div className='pos-relative'>
                                    <DatePicker
                                        selected={fromDate}
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control datepicker-h w-100"
                                        name="fromDate"
                                        dropdownMode="select"
                                        autoComplete="off"
                                        showYearDropdown
                                        showMonthDropdown
                                        maxDate={new Date()}
                                        onChange={setFromDate}
                                    />
                                    <div className='pos-calender'>
                                        <img src={Calander} alt="" />

                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <p className="input-label">To date</p>
                                <div className='pos-relative'>
                                    <DatePicker
                                        selected={toDate}
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control datepicker-h w-100"
                                        name="toDate"
                                        dropdownMode="select"
                                        autoComplete="off"
                                        showYearDropdown
                                        showMonthDropdown
                                        maxDate={new Date()}
                                        onChange={setToDate}
                                    />
                                    <div className='pos-calender'>
                                        <img src={Calander} alt="" />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row search-card mt-3'>
                            <DataTable
                                columns={columns}
                                data={data}
                                pagination
                                actions={actionsMemo}
                            />
                        </div>
                    </div>
                </div>

            </Layout>
        </div>
    );
};
