import React from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import '../../components/styles/UserProfile.css';
import backarrow from '../../assets/images/arrow_back.svg';
import { Layout } from '../layout/Layout';

interface UserProfileProps {

}

export const UserProfile: React.FC<UserProfileProps> = () => {
    const { state } = useLocation<any>();
    const history = useHistory();

    const gotoReports = () => {
        history.goBack();
    };

    return (
        <div>
            <Layout nav>
                <div className='d-flex' role="button" onClick={gotoReports} aria-hidden="true">
                    <div>
                        <img
                            src={backarrow}
                            alt="Calender"
                        />
                    </div>
                    <div className='back-arrow'>Back to reports</div>
                </div>
                <div className="card card-padding my-md-5 my-0">
                    <div className="p-md-5 p-3 login-card">
                        <div className='re-card-body mb-3'>
                            <div className='col-md-6 re-card-title'>{`${state.forename || ''} ${state.surname || ''}`} report</div>
                            <div className='col-md-6 subtitle text-start text-md-end result-block'>
                                Result: <span className={`re-span ${state.result?.toLowerCase()}`}>{state.result}</span>
                            </div>
                        </div>

                        <div className='re-sub-card mb-3'>
                            <div className='re-card-body mb-3'>
                                <div className='col-md-6 subtitle'>Email address: <span className='re-font-w'>{state.email}</span></div>
                                <div className='col-md-6 subtitle ml-2'>NHS number: <span className='re-font-w'>{state.nhs}</span></div>
                            </div>
                            <div className='re-card-body mb-3'>
                                <div className='col-md-6 subtitle'>Mobile number: <span className='re-font-w'>{state.mobile_no}</span></div>
                                <div className='col-md-6 subtitle ml-2'>Assignment number: <span className='re-font-w'>{state.assignment_number}</span></div>
                            </div>
                        </div>

                        <div className='re-sub-card'>
                            <div className='re-card-body mb-3 mb-md-5'>
                                <div className='col-md-6 subtitle'>Manufacturer of test: <span className='re-font-w'>{state.manufacturer_name}</span></div>
                                <div className='col-md-6 subtitle ml-2'>Tested date: <span className='re-font-w'>{state.tested_on}</span></div>
                            </div>
                            <div className='re-card-body mb-3 mb-md-5'>
                                <div className='col-md-6 subtitle'>Specimen ID number: <span className='re-font-w'>{state.specimen}</span></div>
                                <div className='col-md-6 subtitle ml-2'>Time: <span className='re-font-w'>{state.time}</span></div>
                            </div>
                            <div>
                                <div className='col-md-12 subtitle'>Any further comments: <br /><span className='re-font-w'>
                                    {state.comments}
                                </span></div>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
};
