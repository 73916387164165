let backendDomain;

if (process.env.NODE_ENV === 'production') {
  backendDomain = process.env.REACT_APP_SERVICE_BACKEND;
} else {
  backendDomain = process.env.REACT_APP_SERVICE_BACKEND;
}

if (backendDomain) {
  backendDomain = backendDomain.endsWith('/')
    ? backendDomain
    : `${backendDomain}/`;
}

const configJson = {
  backendDomain,
  authorize: process.env.REACT_APP_NHSMAIL_AUTHORIZE,
  clientID: process.env.REACT_APP_NHSMAIL_CLIENT_ID,
  redirectUrl: process.env.REACT_APP_NHSMAIL_REDIRECT_URI,
  logout: process.env.REACT_APP_NHSMAIL_LOGOUT
};

export default configJson;
