import React from 'react';

import '../../styles/input.css';

interface TextInputProps {
  placeholder: string;
  disabled?: boolean;
  label?: string;
  type: string;
  name?: string;
  addClass?: string;
  values?: string | number | Array<string>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  errors?: string | Array<string> | null;
  required: boolean;
}

const TextInput = ({
  label,
  placeholder,
  disabled,
  type,
  onChange,
  addClass,
  name,
  errors,
  onBlur,
  values,
  required,
}: TextInputProps) => {
  return (
    <div>
      <div className="input-group">
        {label && (
          <label className="font-size mb-2" htmlFor={name}>
            {label}
            {required && <span className="star"> *</span>}
          </label>
        )}
        <input
          type={type}
          className={`form-control w-100 ${addClass || ''} ${errors ? 'input-error is-invalid' : ''
            }`}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          value={values}
          autoComplete="off"
        />
        {errors && <div className="text-danger form-error-msg">{errors}</div>}
      </div>
    </div>
  );
};

export default React.memo(TextInput);
