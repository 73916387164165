import React from 'react'

import { Link, useLocation } from 'react-router-dom';

import '../../styles/PrimaryNav.css';

interface PrimaryNavProps {

}

const navMenu = [
    {
        title: 'My dashboard',
        link: '/dashboard',
        isActive: false
    },
    {
        title: 'Add new report',
        link: '/add-report',
        isActive: false
    },
    // {
    //     title: 'Report',
    //     link: '/reports',
    //     isActive:false
    // },

]

const adminNavMenu = [
    {
        title: 'Dashboard',
        link: '/admin-dashboard',
        isActive: false
    },
    {
        title: 'Report',
        link: '/reports',
        isActive: false
    },
    // {
    //     title: 'Notification',
    //     link: '/reports',
    //     isActive:false
    // },
]
const menus = 'admin';
const Finalmenu = (menus === 'admin') ? adminNavMenu : navMenu


export const PrimaryNav: React.FC<PrimaryNavProps> = () => {
    const location = useLocation();
    return (
        <div className="pri-nav-wrap">
            {Finalmenu && Finalmenu.map((item: any) => (
                <React.Fragment key={item.title}>
                    <Link
                        className={`pri-nav-wrap-item nav-item-margin nav-font-size ${location.pathname.includes(item.link) ? 'active' : ''}`}
                        to={item.link}
                    > {item.title} </Link>
                </React.Fragment>
            ))}
        </div>
    );
}