import React, { useCallback, useState } from 'react';

import { useFormik } from 'formik';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';

import Calander from '../../assets/images/Calander.svg';
import Device from '../../assets/images/Device.svg';
import Time from '../../assets/images/Time.svg';
import { TextInput, RadioInput, Button, TextArea, StatusCard } from '../../components';
import useCommonContext from '../../context/provider/CommonContext';
import { addReport } from '../../services/api/ReportAPI';
import validationErrors from '../../services/ValidationSchema';
import { Layout } from '../layout/Layout';
import { confirmationRadio, resultOptions } from './Options';

interface AddReportProps { }

// Validation Yup Schema for Email change
const validationSchemaProfile = Yup.object({
    rf_time_zone: Yup.string().trim().required(validationErrors[1001]),
    rf_comments: Yup.string().trim(),
    rf_repeat_test: Yup.string().trim().required(validationErrors[1001]),
    rf_result: Yup.string().trim().required(validationErrors[1001]),
    // rf_specimen_id: Yup.string().trim().required(validationErrors[1001]),
    rf_manufacturer_name: Yup.string().trim().required(validationErrors[1001]),
    // rf_test: Yup.string().trim().required(validationErrors[1001]),
    rf_type: Yup.string().trim().required(validationErrors[1001]),
    rf_test_date: Yup.date().required(validationErrors[1001]),
    rf_specimen_id: Yup.string().trim()
        .required(validationErrors[1001])
        .test('maxDigits', validationErrors[1028], (number) => {
            return String(number).length <= 15;
        })
});

export const AddReport: React.FC<AddReportProps> = () => {
    const { commonDispatch } = useCommonContext();
    const [statusCard, setStatusCard] = useState({
        type: 'success',
        message: '',
    });

    const clearError = useCallback(() => {
        setTimeout(() => {
            setStatusCard({
                type: 'success',
                message: '',
            });
        }, 5000);
    }, []);

    const formik = useFormik({
        initialValues: {
            rf_time_zone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
            rf_comments: '',
            rf_repeat_test: '',
            rf_result: '',
            rf_specimen_id: '',
            rf_manufacturer_name: '',
            rf_test: '',
            rf_type: 'covid',
            rf_test_date: new Date(),
        },
        validationSchema: validationSchemaProfile,
        async onSubmit(values, { resetForm }) {
            try {
                // commonDispatch({ type: 'SET_LOADING' });
                const addStatus = await addReport(values);
                console.log('addStatus', values);
                console.log('addStatus', addStatus);

                if (addStatus) {
                    setStatusCard({
                        type: 'success',
                        message: 'Form submitted successfully.',
                    });
                    resetForm();
                }
                setTimeout(() => {
                    commonDispatch({ type: 'UNSET_LOADING' });
                }, 2000);
                clearError();
            } catch (error) {
                setStatusCard({
                    type: 'danger',
                    message: error.message,
                });
                resetForm();
                clearError();
            }
        },
    });



    return (
        <div>
            <Layout nav>
                <div className="content-card">
                    <div className="content-inner-card form card-padding">
                        <div className=''>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="container mt-1">
                                    <p className="input-label">
                                        Date of COVID-19 test performed <span className="star">*</span>
                                    </p>
                                    <div className='pos-relative'>
                                        <DatePicker
                                            selected={formik.values.rf_test_date}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control datepicker-h w-100"
                                            name="rf_test_date"
                                            dropdownMode="select"
                                            showYearDropdown
                                            showMonthDropdown
                                            maxDate={new Date()}
                                            onChange={(date: any) => {
                                                formik.setFieldValue(
                                                    'rf_test_date',
                                                    moment(
                                                        `${moment(date).format('YYYY-MM-DD')} ${moment(
                                                            formik.values.rf_test_date,
                                                            ['HH:mm']
                                                        ).format('HH:mm')}`,
                                                        'YYYY-MM-DD HH:mm'
                                                    ).toDate()
                                                );
                                            }}
                                        />
                                        <div className='pos-calender'>
                                            <img src={Calander} alt="" />
                                        </div>
                                    </div>

                                    <div className="h-5" />
                                    <p className="input-label">
                                        Time of COVID-19 test performed <span className="star">*</span>
                                    </p>
                                    <div className='pos-relative'>
                                        <DatePicker
                                            selected={formik.values.rf_test_date}
                                            className="form-control datepicker-h w-100"
                                            name="rf_test_date"
                                            dropdownMode="select"
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            onChange={(date: any) => {
                                                formik.setFieldValue(
                                                    'rf_test_date',
                                                    moment(
                                                        `${moment(formik.values.rf_test_date).format(
                                                            'YYYY-MM-DD'
                                                        )} ${moment(date, ['HH:mm']).format('HH:mm')}`,
                                                        'YYYY-MM-DD HH:mm'
                                                    ).toDate()
                                                );
                                            }}
                                        />
                                        <div className='pos-calender'>
                                            <img src={Time} alt="" />

                                        </div>
                                    </div>

                                    <div className="h-5" />
                                    <TextInput
                                        label="Manufacturer of test (found on box)"
                                        placeholder=""
                                        name="rf_manufacturer_name"
                                        type="text"
                                        required
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.rf_manufacturer_name}
                                        errors={
                                            formik.touched.rf_manufacturer_name &&
                                                formik.errors.rf_manufacturer_name
                                                ? formik.errors.rf_manufacturer_name
                                                : null
                                        }
                                    />


                                    <div className="h-5" />


                                    <TextInput
                                        label="Specimen ID number (found on device - please see the below image for reference)"
                                        placeholder=""
                                        name="rf_specimen_id"
                                        type="text"
                                        required
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.rf_specimen_id}
                                        errors={
                                            formik.touched.rf_specimen_id &&
                                                formik.errors.rf_specimen_id
                                                ? formik.errors.rf_specimen_id
                                                : null
                                        }
                                    />
                                    <div className="h-5" />
                                    <img className="device-img" src={Device} alt="" />
                                    <div className="h-5" />

                                    <p className="input-label">Result - recorded as positive, negative and invalid <span className="star">*</span></p>
                                    <div className='d-md-flex'>
                                        {resultOptions.map((result: any) => (
                                            <RadioInput
                                                type="radio"
                                                label={result.label}
                                                name="rf_result"
                                                value={result.value}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={formik.values.rf_result === result.value && true}
                                            />
                                        ))}
                                    </div>

                                    {formik.touched.rf_result && formik.errors.rf_result ? (
                                        <div className="text-danger form-error-msg">
                                            {formik.errors.rf_result}
                                        </div>
                                    ) : null}
                                    <div className="h-5" />
                                    <p className="input-label">
                                        If invalid, confirmation that a repeat test has been performed <span className="star">*</span>
                                    </p>
                                    {confirmationRadio.map((confirmation: any) => (
                                        <RadioInput
                                            type="radio"
                                            label={confirmation.label}
                                            name="rf_repeat_test"
                                            value={confirmation.value}
                                            onChange={formik.handleChange}
                                            checked={
                                                formik.values.rf_repeat_test === confirmation.value &&
                                                true
                                            }
                                            onBlur={formik.handleBlur}
                                        />
                                    ))}
                                    {formik.touched.rf_repeat_test &&
                                        formik.errors.rf_repeat_test ? (
                                            <div className="text-danger form-error-msg">
                                                {formik.errors.rf_repeat_test}
                                            </div>
                                        ) : null}

                                    <div className="h-5" />

                                    <TextArea
                                        label="Any further comments (such as your experience using the test or anything that would support this survey)"
                                        addClass=""
                                        name="rf_comments"
                                        placeholder=""
                                        required={false}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        values={formik.values.rf_comments}
                                        errors={
                                            formik.touched.rf_comments && formik.errors.rf_comments
                                                ? formik.errors.rf_comments
                                                : null
                                        }
                                    />

                                    <div className="spacer-10" />
                                    {statusCard && statusCard.message && (
                                        <StatusCard
                                            type={statusCard.type}
                                            message={statusCard.message}
                                        />
                                    )}
                                    <div className="spacer-10" />
                                    <div className="text-center">
                                        <Button
                                            btntype="submit"
                                            text="ADD REPORT"
                                            addClass="primary-btn px-4"
                                        />
                                    </div>
                                    <div className="spacer-20" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
};
