import React, { useCallback, useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Button, StatusCard, TextInput } from '../../components';
import '../../components/styles/styles.css';
import useAuthContext from '../../context/provider/AuthContext';
import useCommonContext from '../../context/provider/CommonContext';
import { setToken } from '../../helpers/ApiSerivce';
import { history } from '../../helpers/History';
import { getUserInfo, login } from '../../services/api/AuthAPI';
import validationErrors from '../../services/ValidationSchema';
import { Layout } from '../layout/Layout';
import '../../components/styles/Login.css';


interface AdminLoginProps { }

// Validation Yup Schema for Email change
const validationSchemalogin = Yup.object({
    email: Yup.string()
        .email(validationErrors[1002])
        .required(validationErrors[1001]),
    password: Yup.string().required(validationErrors[1001]),
});

export const AdminLogin: React.FC<AdminLoginProps> = () => {
    const { authDispatch } = useAuthContext();
    const { commonDispatch } = useCommonContext();
    const [statusCard, setStatusCard] = useState({
        type: 'success',
        message: '',
    });

    const clearError = useCallback(() => {
        setTimeout(() => {
            setStatusCard({
                type: 'success',
                message: '',
            });
        }, 5000);
    }, []);

    // Formik Handler
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchemalogin,
        async onSubmit(values, { resetForm }) {
            await login(values)
                .then(async (loginResponse) => {
                    commonDispatch({ type: 'SET_LOADING' });

                    if (loginResponse && loginResponse.result) {
                        setStatusCard({
                            type: 'success',
                            message: 'You have successfully logged in. Please wait...',
                        });
                        setTimeout(async () => {
                            authDispatch({ type: 'LOGIN' });
                            setToken(loginResponse.result.token);
                            const userInfo = await getUserInfo();
                            if (userInfo) {
                                const responseModified = { ...userInfo.result };
                                console.log('responseModified', responseModified);
                                localStorage.setItem('name', `${responseModified.userData.forename} ${responseModified.userData.surname}`)
                                localStorage.setItem('role', responseModified.userData.role === 1 ? 'admin' : 'staff')
                                authDispatch({
                                    type: 'LOAD_USER',
                                    user: responseModified.userData,
                                });
                            }
                            if (loginResponse.result.first_login) {
                                history.push('/profile');
                            } else {
                                history.push('/dashboard');
                            }
                        }, 3000);
                    } else {
                        commonDispatch({ type: 'UNSET_LOADING' });
                        setStatusCard({
                            type: 'danger',
                            message: 'Something went worng, Please try again!',
                        });
                        resetForm();
                    }
                    clearError();
                })
                .catch((error) => {
                    commonDispatch({ type: 'UNSET_LOADING' });
                    setStatusCard({
                        type: 'danger',
                        message: error.msg,
                    });
                    clearError();
                });
        },
    });
    // const [rememberMe, setRememberMe] = useState<boolean>(false);

    return (
        <div>
            <Layout>
                <h2 className="page-header">Login</h2>
                <div className='spacer-40' />
                <div className="content-card">
                    <div className="content-inner-card form">
                        <div className='form-container'>
                            <form onSubmit={formik.handleSubmit}>
                                <TextInput
                                    label="Email address"
                                    placeholder="Enter email address"
                                    name="email"
                                    type="email"
                                    required={false}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    values={formik.values.email}
                                    errors={
                                        formik.touched.email && formik.errors.email
                                            ? formik.errors.email
                                            : null
                                    }
                                />
                                <div className="spacer-30" />
                                <TextInput
                                    label="Password"
                                    placeholder="Enter password"
                                    name="password"
                                    type="password"
                                    required={false}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    values={formik.values.password}
                                    errors={
                                        formik.touched.password && formik.errors.password
                                            ? formik.errors.password
                                            : null
                                    }
                                />

                                {/* <CheckInput
                                      label="remember me"
                                      name="remember_me"
                                      type="checkbox"
                                      onChange={(e) => setRememberMe(e.target.checked)}
                                      selectedValue={rememberMe}
                                  /> */}
                                <div className="spacer-30" />
                                {statusCard && statusCard.message && (
                                    <StatusCard
                                        type={statusCard.type}
                                        message={statusCard.message}
                                    />
                                )}
                                <div className="button-center">
                                    <Button
                                        btntype="submit"
                                        text="LOGIN"
                                        addClass="primary-btn"
                                    />

                                    {/* <div className="text-center">
                      <Link className='forget-color' to="/">I have forgetten my password</Link>
                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="h-5" />
            </Layout>
        </div>
    );
};