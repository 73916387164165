import React from 'react';

import NHSLogo from '../../assets/images/nhs-login-button.svg';
import configJson from '../../config/config';
import { Layout } from '../layout/Layout';


export default function Landing() {
    // const isADAuthenticated = useIsAuthenticated();

    return (
        <Layout>
            <h2 className='page-header'>Staff COVID-19 Test Results</h2>
            {/* <div className="mb-10">
                {isADAuthenticated ? <SignOutButton /> : <SignInButton />}
            </div> */}
            <div className="spacer-40" />
            <div className='home-content p-3'>
                <p>
                    Staff have to order lateral flow tests through the national system from the <a href="https://www.gov.uk/order-coronavirus-rapid-lateral-flow-tests" target="_blank" rel="noreferrer"> gov.uk website</a>. You will not receive a supply via the Trust. When asked the area you work in, please select mental health trust regardless of your role then type in the Trust name.
                </p>
                <div className='spacer-20' />
                <p>
                    You need to self-administer 2 tests per week, 3-4 days apart. Ideally you would do the test and get the result before you start your shift.

                </p>
                <div className='spacer-20' />
                <p>
                    You should get your result within 30mins of administering the test and are asked to log each result in this system.

                </p>
                <div className='spacer-20' />

                <p><b>If your lateral flow test result is positive</b> there is no longer a requirement to take a PCR for confirmation. You will need to self-isolate immediately, let your line manager know and report the result to <a href="https://www.gov.uk/report-covid19-result" target="_blank" rel="noreferrer">Report a COVID-19 rapid lateral flow test result - GOV.UK</a>. More information on self-isolation and exceptions can be found on the Trust Intranet COVID 19 guidance page <a href="https://intranet.hwhct.nhs.uk/covid19" target="_blank" rel="noreferrer">https://intranet.hwhct.nhs.uk/covid19</a>.</p>
                <div className='spacer-20' />
                <p>
                    <b> Negative results </b>still need to be recorded on the form but don’t need further verification and you can continue in your role as normal, ensuring you continue complying with all other IPC guidance inside and outside of work and continuing your twice weekly lateral flow tests.
                </p>
                <div className='spacer-30' />
                <div className='home-nhs-wrap'>
                    <p>To record your COVID 19 Lateral Flow test results, click on Continue with NHS Mail.</p>
                    <div className='d-flex justify-content-center justify-content-md-start'>
                        {/* <Link to="/signup" className='primary-btn'> SIGNUP</Link> */}
                        {/* <Link to="https://fs.nhs.net/adfs/oauth2/authorize?response_type=code&client_id=c30dca48-cd83-4259-9f83-291d2695253e&resource=https://covidappuat.hwphis.nhs.uk/oidc/sp&redirect_uri=https://covidappuat.hwphis.nhs.uk/oidc/sp&client-request-id=ca0facb9-b210-40fd-d452-008000000071&scope=user.read+openid+profile+email" className='primary-btn ml-15'> LOGIN</Link> */}
                        <form method="GET" action={configJson.authorize}>
                            <input type="hidden" name="redirect_uri" value={configJson.redirectUrl} />
                            <input type="hidden" name="client_id" value={configJson.clientID} />
                            <input type="hidden" name="response_type" value="code" />
                            <input type="hidden" name="response_mode" value="query" />
                            <input type="hidden" name="scope" value="user.read openid profile email" />
                            <div className="text-center">
                                <button type="submit" className="nhs-login aw-nhs-login-btn" value="Continue with NHS mail">
                                    <img src={NHSLogo} alt="Continue with NHS mail" />
                                        Continue with NHS mail
                                    </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className='spacer-40' />
                <h4 className='section-heading'>Exceptions:</h4>
                <div className='spacer-10' />
                <p>
                    This is for patient facing staff only and anyone who is working in a clinical setting.
                </p>
                <div className='spacer-10' />
                <p>
                    Your home test kit box is for you only. Please don&apos;t be tempted to share it with family, friends or other colleagues.
                </p>
                <div className='spacer-20' />
                <p>
                    If you have received a positive PCR test result, you should test daily from days 5-11, if a negative result revert to twice weekly. If still positive test after day 11 test regularly weekly for example. If you are unwell isolate and once you have a clear test return to twice weekly screens.

                </p>
                <div className='spacer-20' />

                <p> Daily tests if requested by IPC as part of management of an increase of cases, in an area or an outbreak.                </p>
                <div className='spacer-40' />
                <h4 className='section-heading'>Further information and support:</h4>
                <div className='spacer-10' />
                <p>
                    For general queries about home testing kits, please speak to your line manager in the first instance.
                </p>
                <div className='spacer-20' />
                <p>
                    If you discover a fault with your home testing kit, report this through the Ulysses reporting system.
                </p>
                <div className='spacer-20' />
                <p>
                    For guidance on self-administering the test visit the <a href="https://learninghub.nhs.uk/self-swab" target="_blank" rel="noreferrer">NHS Learning Hub</a>.
                </p>
                <div className='spacer-50' />
                <div className='spacer-50' />
            </div>
        </Layout>
    );
}
