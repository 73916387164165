import React from 'react'

import { Layout } from '../layout/Layout';

interface ReportsProps { }

export const Reports: React.FC<ReportsProps> = () => {
    return (
        <div>
            <Layout nav>
                <h2 className='text-center'>Coming soon</h2>
            </Layout>
        </div>
    );
}