import apiFunctions from '../../helpers/ApiSerivce';
import {
    IAdminDashboardPayload,
    IAdminDashboardData,
    IAdminReportPayload,
    IAdminReportData
} from '../../model/Types';
import siteUrls from '../SiteURLs';

interface IAdminDashboardResponse {
    result: IAdminDashboardData
}

interface IAdminReportResponse {
    result: Array<IAdminReportData>
}

export function getAdminDashboardData(payload?: IAdminDashboardPayload): Promise<IAdminDashboardResponse> {
    return apiFunctions.post(siteUrls.adminDashboard, payload || {});
}

export function getAdminReportData(payload?: IAdminReportPayload): Promise<IAdminReportResponse> {
    return apiFunctions.post(siteUrls.adminReports, payload || {});
}
