import React, { useEffect, useState } from 'react';

import useCommonContext from '../../context/provider/CommonContext';
import '../styles/loading.css';

export interface LoadingProps {
  status?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ status }) => {
  const {
    state: { isLoading },
  } = useCommonContext();

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (status) {
      setLoader(status);
    }
  }, [status]);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  return (
    <div>
      {loader && (
        <div className="loading-wrapper">
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path stroke-current text-primary-100"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke="currentColor"
              strokeWidth="5"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Loading;
