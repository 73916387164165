import React from 'react';

import { Redirect, Route, RouteProps } from 'react-router-dom';

export interface IPrivateRoute extends RouteProps {
  redirectPath?: string;
}

const PrivateRoute = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    !localStorage.getItem('access-token') ? (
      React.createElement(component, props)
    ) : (
      <Redirect to="/dashboard" />
    );
  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
