/*
reducers are called from context. so look in AuthContext.ts
*/
import { IUserInfo } from '../../model/Types';

// the possible values for action.type in
// our AuthReducer fn
export type AuthAction =
  | {
      type: 'LOGIN';
    }
  | {
      type: 'LOAD_USER';
      user: IUserInfo;
    }
  | { type: 'LOGOUT' };

export interface AuthState {
  isAuthenticated: boolean;
  user: IUserInfo | null;
}

// when we hook up the app, the user is not
// going to be present in state.
export const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
};

// a reducer give back a state
export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, isAuthenticated: true };
    case 'LOAD_USER': {
      return { ...state, user: action.user };
    }
    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }
    default:
      return state;
  }
};
