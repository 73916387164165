import React from 'react';

import DownArrow from '../../../assets/images/Arrow-2.svg';
import Logo from '../../../assets/images/NHS-logo.png';
import '../../styles/header.css';
import { AuthContextProvider } from '../../../context/provider/AuthContext';
import { history } from '../../../helpers/History';
import { logout } from '../../../services/api/AuthAPI';

interface HeaderProps {
    dropDown: boolean;
}

export const Header: React.FC<HeaderProps> = ({ dropDown }) => {
    return (
        <AuthContextProvider>
            <div className="top-header">
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <a href="/">
                            <img className="nhs-logo" src={Logo} alt="Herefordshire and Worcestershire Health and Care NHS Trust" />
                        </a>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <h2 className="">Staff COVID-19 Test Results</h2>

                        {dropDown && (
                            <div className="text-end mt-3">
                                <div className="btn-group">
                                    <button
                                        type="button"
                                        className="dropdown-toggle bg-transparent text-white profile-dropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <span className='font-weight'>{localStorage.getItem('name')}</span>{' '}
                                        <img src={DownArrow} alt="" height="8px" className='droparrow' />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <button
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    history.push('/profile');
                                                }}
                                                type="button"
                                            >
                                                Profile
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    logout();
                                                }}
                                                type="button"
                                            >
                                                Logout
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </AuthContextProvider>
    );
};