// // companion for AppCommonContext
// app wide
// just loading for now

export type CommonAction =
  | {
      type: 'SET_LOADING';
    }
  | {
      type: 'UNSET_LOADING';
    };

export interface CommonState {
  isLoading: boolean;
}

export const initialState: CommonState = {
  isLoading: false,
};

export const commonReducer = (
  state: CommonState,
  action: CommonAction
): CommonState => {
  console.log(action);

  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'UNSET_LOADING':
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
