import React, { useEffect, useState } from 'react'

import moment from 'moment'

import { Button } from '../../components';
import useCommonContext from '../../context/provider/CommonContext';
import { history } from '../../helpers/History';
import { getRapidTestData } from '../../services/api/AuthAPI';
import { Layout } from '../layout/Layout'

interface DashboardProps {

}

export const Dashboard: React.FC<DashboardProps> = () => {
    const [rapidTestReport, setRapidTestReport] = useState<any>([]);

    const { commonDispatch } = useCommonContext();
    let rapidTestData: any;
    async function getRapidData() {
        getRapidTestData().then((res) => {
            rapidTestData = res.result;
            console.log('rapidTestData..', rapidTestData);
            setRapidTestReport(rapidTestData);
        }).catch((err) => {
            console.log(" getRapidData err...", err);
        })

    }
    useEffect(() => {
        getRapidData()
        commonDispatch({ type: 'SET_LOADING' });
        setTimeout(() => {
            commonDispatch({ type: 'UNSET_LOADING' });
        }, 2000);
    }, [])

    return (
        <div>
            <Layout nav>
                <div className="card card-padding my-md-5 my-0">
                    <div className="p-md-5 p-3 login-card">

                        <h2 className="fw-bold mt-3 mt-md-0">Welcome {localStorage.getItem('name')}</h2>
                        <div className="h-5" />
                        <div className="d-md-flex justify-content-between">
                            <div className="">
                                <h3 className="fw-bold">Today</h3>
                                <h5>{moment().format('Do MMMM, YYYY')}</h5>
                            </div>
                            <div className="p-2 mt-2 text-center">
                                <Button btntype="button" onClick={() => history.push('/add-report')} text="ADD NEW REPORT"
                                    addClass='primary-btn px-4' />
                            </div>
                        </div>
                        <br />
                        <div className="h-5" />

                        <h5>Recent reports</h5>

                        {rapidTestReport.map((child: any) => (
                            <div className="card card-border mb-4 p-2">
                                <div className="card-body d-md-flex justify-content-between ">
                                    <div className="">
                                        <div>
                                            <h5 className="mx-2">{moment(child.test_date).format('Do MMMM, YYYY')} </h5>
                                        </div>
                                        <div className='button-space'>
                                            <div className={`banner stmt-info d-flex-center mx-1 ${child.result.toLowerCase()}-bg`}> {child.result}</div>

                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="">
                                            <h6 className="mx-2">Specimen ID</h6>

                                        </div>
                                        <div className="">
                                            <h6 className='spe-bg spe-id d-flex-center mx-1'>{child.specimen_id}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="card coivd-bg card-border">
                            <div className="card-body p-3 p-md-5">
                                <div className='row justify-content-between'>
                                    <div className='col-md-5'>
                                    <div className="text-md-start text-center">
                                        <h5 className='text-white'>Order coronavirus (COVID-19) rapid lateral flow tests</h5>                                      
                                    </div>
                                    </div>
                                    <div className='col-md-7 ordernow'>
                                    <div className='text-md-end text-center'>
                                        <Button btntype="button" onClick={() => window.open("https://www.gov.uk/order-coronavirus-rapid-lateral-flow-tests", "_blank")} text="ORDER NOW"
                                            addClass='primary-btn' />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
}