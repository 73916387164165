export const confirmationRadio = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Not applicable',
    value: 'not_applicable',
  },
];

export interface optionsValue {
  value: string,
  label: string
}

export const resultOptions = [
  { value: 'Negative', label: 'Negative' },
  { value: 'Positive', label: 'Positive' },
  { value: 'Invalid', label: 'Invalid' }
]

export const ethinicityOptions = [
  { value: 'British', label: 'British' },
  { value: 'Irish', label: 'Irish' },
  { value: 'Any other White background', label: 'Any other White background' },

  { value: 'White and Black Caribbean', label: 'White and Black Caribbean' },
  { value: 'White and Black African', label: 'White and Black African' },
  { value: 'White and Asian', label: 'White and Asian' },

  { value: 'Any other mixed background', label: 'Any other mixed background' },
  { value: 'Indian', label: 'Indian' },
  { value: 'Pakistani', label: 'Pakistani' },

  { value: 'Bangladeshi', label: 'Bangladeshi' },
  { value: 'Any other Asian background', label: 'Any other Asian background' },
  { value: 'Caribbean', label: 'Caribbean' },

  { value: 'African', label: 'African' },
  { value: 'Any other Black background', label: 'Any other Black background' },
  { value: 'Chinese', label: 'Chinese' },

  { value: 'Any other ethnic group', label: 'Any other ethnic group' },
  { value: 'Not stated', label: 'Not stated' }
]

export const employmentOptions = [
  { value: 'Employed by HWHCT', label: 'Employed by HWHCT' },
  { value: 'Agency/NHSP only', label: 'Agency/NHSP only' },
  { value: 'Student', label: 'Student' },
]

export const jobRoleOptions = [
  { value: 'Allied Health Professional', label: 'Allied Health Professional' },
  { value: 'Healthcare Assistant/Support Worker', label: 'Healthcare Assistant/Support Worker' },
  { value: 'Medic', label: 'Medic' },
  { value: 'Corporate Staff/Support Staff', label: 'Corporate Staff/Support Staff' },
  { value: 'Registered Nurse', label: 'Registered Nurse' },
  { value: 'Registered Nursing Associate', label: 'Registered Nursing Associate' },
  { value: 'other', label: 'Other (please specify)' },
]

export const sduOptions = [
  {
    value: 'Mental Health and Learning Disabilities',
    label: 'Mental Health and Learning Disabilities',
  },
  { value: 'CYPF & SPC', label: 'CYPF & SPC' },
  { value: 'Community Care', label: 'Community Care' },
  { value: 'Integrated Community Services', label: 'Integrated Community Services' },
  { value: 'Corporate/Support Services', label: 'Corporate/Support Services' },
]

export const genderOptions = [
  {
    title: 'Male',
    value: 'male',
  },
  {
    title: 'Female',
    value: 'female',
  },
  {
    title: 'Other',
    value: 'other',
  },
];
export const confirmationRadioPositive = [
  {
    title: 'Negative',
    value: 'negative',
  },
  {
    title: 'Positive',
    value: 'positive',
  },
  {
    title: 'Invalid',
    value: 'invalid',
  },
];
