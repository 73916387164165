import axios, { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import Swal from 'sweetalert2'

import { logout } from '../services/api/AuthAPI';

axios.interceptors.response.use(
  (response) =>
    new Promise((resolve) => {
      resolve(response.data);
    }),
  error => {
    if (error.response.status === 401) {
      console.log("interceptors error....", error.response.status);
      Swal.fire({
        title: 'Oops!',
        text: 'Session Exipired, Please login agian!',
        showDenyButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'logout',
      }).then((result) => {
        if (result.isConfirmed) {
          logout()
        }
      })
    }
    return Promise.reject(error.response.data)
  }
);

export function setToken(token: string | null) {
  if (token) {
    localStorage.setItem('access-token', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
}

function getHeaders() {
  const accessToken = localStorage.getItem('access-token');
  if (accessToken) {
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }
  return undefined;
}

const apiFunctions = {
  get: async (url: string): Promise<AxiosResponse['data']> => {
    return axios.get(url, getHeaders());
  },
  post: async (url: string, data: object): Promise<AxiosResponse['data']> => {
    return axios.post(url, data, getHeaders());
  },
  put: async (url: string, data: object): Promise<AxiosResponse['data']> => {
    return axios.put(url, data, getHeaders());
  },
  patch: async (url: string, data: object): Promise<AxiosResponse['data']> => {
    return axios.patch(url, data, getHeaders());
  },
  delete: async (url: string) => {
    return axios.delete(url, getHeaders());
  }
};

export function isTokenValid(token: string) {
  try {
    const decodedJwt: any = jwtDecode(token);
    // const current_time = Date.now().valueOf() / 1000;
    // return decodedJwt.exp > current_time;
    console.log(decodedJwt);

    return decodedJwt || false; // unlimited expiry
  } catch (error) {
    return false;
  }
}

// export default axios;
export default apiFunctions;
