import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { isTokenValid } from '../../helpers/ApiSerivce';
import { history } from '../../helpers/History';
import { getUserInfo } from '../../services/api/AuthAPI';
import {
  AuthAction,
  AuthState,
  authReducer,
  initialState,
} from '../reducers/AuthReducer';
import useCommonContext from './CommonContext';


// a authDispatch can only do action types set in reducer
type AuthContextProps = {
  state: AuthState;
  authDispatch: React.Dispatch<AuthAction>;
  checkAuth?: any
};

const AuthContext = createContext<AuthContextProps>({
  state: initialState,
  authDispatch: () => initialState
});

export function AuthContextProvider(props: React.PropsWithChildren<{}>) {
  const [state, authDispatch] = useReducer(authReducer, initialState);
  const { commonDispatch } = useCommonContext();

  async function checkAuth() {
    const token = await localStorage.getItem('access-token');

    if (!token) return;

    if (isTokenValid(token)) {
      try {
        const userInfo = await getUserInfo();
        if (userInfo) {
          authDispatch({ type: 'LOGIN' });
          const responseModified = { ...userInfo.result };
          localStorage.setItem('name', `${responseModified.userData.forename} ${responseModified.userData.surname}`)
          authDispatch({ type: 'LOAD_USER', user: responseModified.userData });
          if (userInfo && userInfo.result && userInfo.result.userData && userInfo.result.userData.first_login) {
            history.push('/profile');
          }
        } else {
          authDispatch({ type: 'LOGOUT' });
        }
      } catch (error) {
        console.log('error', error);
        commonDispatch({ type: 'UNSET_LOADING' });
      }
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  return <AuthContext.Provider value={{ state, authDispatch, checkAuth }} {...props} />;
}

export default function useAuthContext() {
  return useContext(AuthContext);
}
