import React from 'react';

import Select from 'react-select';
import '../../styles/SelectDropdown.css';

export interface OptionArrayProps {
    value: string | Array<string> | null;
    label: string | Array<string> | null;
}

export interface SelectDropdownProps {
    label?: string;
    onChange?: any;
    onBlur?: any;
    type?: any;
    name?: any;
    optionsArray: Array<OptionArrayProps>;
    errors?: string | null | any;
    placeholder?: string;
    value?: any;
    required: boolean;

}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
    label,
    optionsArray,
    onChange,
    onBlur,
    name,
    errors,
    placeholder,
    value,
    required,

}) => {
    const customStyles = {};

    const defaultValue = (
        optionsArray: Array<OptionArrayProps>,
        value: string
    ) => {
        return optionsArray
            ? optionsArray.find((optionsArray) => optionsArray.value === value)
            : '';
    };

    return (
        <div className="input-group">
            {label && <label className="font-size mb-2" htmlFor={name}>
                {label}
                {required && <span className="star"> *</span>}
            </label>}
            <Select
                name={name}
                className="react-select-container w-100"
                classNamePrefix="react-select"
                styles={customStyles}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                options={optionsArray}
                value={defaultValue(optionsArray, value) || null}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#0169be',
                        primary: '#0169be',
                    },
                })}
            />
            {errors && <div className="text-danger form-error-msg">{errors}</div>}
        </div>
    );
};

export default React.memo(SelectDropdown);
