import React from 'react';

import { AuthContextProvider } from './context/provider/AuthContext';
import './App.css';
import { CommonContextProvider } from './context/provider/CommonContext';
import AppRoute from './routes/AppRoute';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  return (
  <AuthContextProvider>
    <CommonContextProvider>
    <AppRoute />
    </CommonContextProvider>
  </AuthContextProvider>
  );
}

export default App;
