import React from 'react';

interface StatusCardProps {
  type: string;
  message: string;
}

export const StatusCard: React.FC<StatusCardProps> = ({ type, message }) => {
  return (
    <div
      className={`alert alert-${type} alert-dismissible fade show`}
      role="alert"
    >
      {message}
    </div>
  );
};
