import React from 'react';

import '../../styles/button.css';

export interface ButtonProps {
  text?: string;
  addClass?: string;
  btntype: 'submit' | 'button';
  disabled?: boolean;
  onClick?: any;
}

const Button = ({
  text,
  addClass,
  btntype,
  disabled,
  onClick,
}: ButtonProps) => {
  return (
    <>
      {btntype === 'button' && (
        <button
          onClick={onClick}
          type="button"
          className={`btn ${addClass}`}
          disabled={disabled}
        >
          {text}
        </button>
      )}
      {btntype === 'submit' && (
        <button
          onClick={onClick}
          type="submit"
          className={`btn ${addClass}`}
          disabled={disabled}
        >
          {text}
        </button>
      )}
    </>
  );
};

export default React.memo(Button);
