import configJson from '../config/config';

const siteUrlsJson = {
  ourSiteUrls: {
    login: `login`,
    getAccessToken: `get_access_token`,
    register: `signup`,
    getUserInfo: `get_user_info`,
    updateProfile: `update_profile`,
    rapidForm: `rapidForm`,
    resetPassword: `auth/reset-password`,
    forgotPassword: `auth/send-reset-password-mail`,
    checkForgotPassToken: `account/check-forgot-token-expire`,
    verifyAccountEmail: `auth/verify-user`,
    verifyPasswordToken: `auth/verify-token-valid`,
    adminDashboard: `admin/dashboard`,
    adminReports: `admin/reports`
  },
  outerDomainUrls: {},
};
function checkInnerJson(jsonData: any) {
  if (jsonData) {
    for (const key in jsonData) {
      if (typeof jsonData[key] === 'string') {
        jsonData[key] = `${configJson.backendDomain}${jsonData[key]}`;
      } else {
        jsonData[key] = checkInnerJson(jsonData[key]);
      }
    }
  }
  return jsonData as typeof siteUrlsJson.ourSiteUrls;
}
const siteUrls = {
  ...checkInnerJson(siteUrlsJson.ourSiteUrls),
  outerDomainUrls: siteUrlsJson.outerDomainUrls,
};
export default siteUrls;
