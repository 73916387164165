import React from 'react';

import '../../styles/TextArea.css';

export interface TextAreaProps {
    style?: string;
    text?: string;
    placeholder?: string;
    disabled?: boolean;
    label?: string;
    name?: string;
    addClass?: string;
    values?: string | number;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    errors?: string | null;
    id?: string;
    required: boolean;

}

const TextArea = ({
    label,
    placeholder,
    disabled,
    onChange,
    addClass,
    name,
    errors,
    onBlur,
    values,
    id,
    required
}: TextAreaProps) => {
    return (
        <div>
            <div className="input-group">
                {label && <label className="font-size mb-2" htmlFor={name}>{label}
                {required && <span className="star"> *</span>}</label>}
            </div>
            <textarea
                id={id}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled}
                name={name}
                className={`form-control w-100 text-area-h${addClass || ''}`}
                onBlur={onBlur}
                value={values}
            />
            {errors && <div className="text-danger form-error-msg">{errors}</div>}
        </div>
    );
};

export default React.memo(TextArea);
