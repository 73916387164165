import React, { Suspense } from 'react';

import { Redirect, Router, Switch } from 'react-router-dom';

import useAuthContext from '../context/provider/AuthContext';
import { history } from '../helpers/History';
import { AdminDashboard } from '../pages/admin/AdminDashboard';
import { AdminReport } from '../pages/admin/AdminReport';
import { UserProfile } from '../pages/admin/UserProfile';
import { AdminLogin } from '../pages/auth/AdminLogin';
import { Login } from '../pages/auth/Login';
// import { Signup } from '../pages/auth/Signup';
import Landing from '../pages/static/Landing';
import { AddReport } from '../pages/user/AddReport';
import { Dashboard } from '../pages/user/Dashboard';
import { Profile } from '../pages/user/Profile';
import { Reports } from '../pages/user/Reports';
import PrivateRoute from './PrivateRoute';
import ProtectedRoute from './ProtectedRoute';


const AppRoute: React.FC = () => {
  const { state: { user } } = useAuthContext();

  const userDasboard = localStorage.getItem('role') === 'admin' ? AdminDashboard : Dashboard;
  const userReport = localStorage.getItem('role') === 'admin' ? AdminReport : Reports;

  return (
    <Router history={history}>
      <Suspense fallback={<h6>Loading…</h6>}>
        <Switch>
          <PrivateRoute exact path="/" component={Landing} />
          {/* <PrivateRoute exact path="/" component={AdminReport} /> */}
          <PrivateRoute exact path="/login" component={AdminLogin} />
          <PrivateRoute exact path="/oidc/sp" component={Login} />
          {/* <PrivateRoute exact path="/signup" component={Signup} /> */}
          <ProtectedRoute path="/add-report" component={AddReport} />
          <ProtectedRoute path="/dashboard" component={userDasboard} />
          <ProtectedRoute path="/profile" component={() => <Profile user={user} />} />
          <ProtectedRoute path="/reports" component={userReport} />
          <ProtectedRoute path="/user-profile" component={UserProfile} />

          {/* Admin  */}

          {/* <PrivateRoute exact path="/admin-dashboard" component={AdminDashboard} /> */}
          {/* <PrivateRoute exact path="/" component={UserProfile} /> */}
          {/* <PrivateRoute path="/admin-report" component={AdminReport} /> */}
          <Redirect from='*' to='/' />

        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRoute;