import React, { ChangeEvent } from 'react';

import '../../styles/radio.css';

interface IRadioInputOptions {

    checked?: boolean;
    label?: string;
    name: string;
    type: string;
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;  
}
const RadioInput = function Radio({
    checked,
    label,
    onChange,
    name,
    type,
    onBlur, 
    value,  

}: IRadioInputOptions) {
    return (
        <div className="btn-group m-2 radio-button" role="group">
            <input className="btn-check" id={value} autoComplete="off"
                type={type}
                checked={checked}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
            />
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="btn btn-outline-primary radio-bt-color" htmlFor={value}> {label}</label>
        </div>
    );
};

export default React.memo(RadioInput);
