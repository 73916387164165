import React from 'react';

// import { SelectDropdown } from '../../components';
import useCommonContext from '../../context/provider/CommonContext';
import { IAdminDashboardData } from '../../model/Types';
import { getAdminDashboardData } from '../../services/api/AdminAPI';
import { Layout } from '../layout/Layout';
import "../../components/styles/AdminDashboard.css";

const initialData: IAdminDashboardData = {
    userCount: 0,
    reportSubmissionCount: 0,
    positive: 0,
    negative: 0,
    invalid: 0
};

export const AdminDashboard: React.FC = () => {
    /* const dateCalender = [
        { value: 'All', label: 'All' },
        { value: 'Week', label: 'Week' },
        { value: 'Month', label: 'Month' },
        { value: 'Year', label: 'Year' },
    ]; */
    const { commonDispatch } = useCommonContext();
    const [data, setData] = React.useState<IAdminDashboardData>(initialData);

    const loadDashboardData = async () => {
        const { result } = await getAdminDashboardData();
        setData({ ...initialData, ...result });
        commonDispatch({ type: 'UNSET_LOADING' });
    };

    React.useEffect(() => {
        commonDispatch({ type: 'SET_LOADING' });
        loadDashboardData();
    }, []);

    return (
        <div>
            <Layout nav>
                <div className="card card-padding my-md-5 my-0">
                    <div className="p-md-5 p-3 login-card">                     
                        <div>
                            <div className='db-card'>
                                <div className='db-card-title'>Overview</div>
                            </div>
                            <div className='db-body'>
                                <div className='p-2 p-md-4'>
                                    {/* <div className='mb-4'>
                                        <div className='row db-date'>
                                            <div className='col-12 col-md-6 db-p db-margin'>Date </div>
                                            <div className='col-12 col-md-6'>
                                                <div className='row db-fb'>
                                                    <div className='col-6 db-p text-start text-md-end'> Filter by</div>
                                                    <div className='col-6'>
                                                        <SelectDropdown
                                                            type="text"
                                                            required
                                                            name="pf_ethnicity"
                                                            label=""
                                                            placeholder=""
                                                            value={dateCalender}
                                                            onChange={(value: any) => {
                                                                console.log('Select ', value)
                                                            }}
                                                            optionsArray={dateCalender}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div> */}

                                    <div className='db-first-card'>
                                        <div className='col-4 col-md-6'>
                                            <h3 className='font-w'>{data.userCount}</h3>
                                            <h5 className='db-font-size'>Users</h5>
                                        </div>
                                        <div className='col-8 col-md-6 left-end'>
                                            <h3 className='font-w '>{data.reportSubmissionCount}</h3>
                                            <h5 className='db-font-size'>Report submissions</h5>
                                        </div>
                                    </div>


                                    <div className="db-card-group">
                                        <div className="db-card-color nav-card">

                                            <h3 className='font-w'>{data.positive}</h3>
                                            <div className="db-card-covid positive">Positive</div>
                                        </div>

                                        <div className="db-card-color nav-card">

                                            <h3 className='font-w'>{data.negative}</h3>
                                            <div className="db-card-covid negative">Negative</div>

                                        </div>
                                        <div className="db-card-color nav-card">

                                            <h3 className='font-w'>{data.invalid}</h3>
                                            <div className="db-card-covid invalid">Invalid</div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
};
