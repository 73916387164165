import React from 'react'

import { Header, PrimaryNav } from '../../components';
import Loading from '../../components/loading/Loading';


interface LayoutProps {
    children: any;
    nav?: boolean | null;
}

export const Layout: React.FC<LayoutProps> = ({ children, nav }) => {
    return (
        <>
            <Header dropDown={nav || false} />
            <Loading />
            {nav && <PrimaryNav />}
            <div className="content-blk">
            <div className="mcontainer p-0">
                {children}
            </div>
            </div>
        
        </>
    );
}