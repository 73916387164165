// companion for appCommonReducer

import React, { createContext, useContext, useReducer } from 'react';

import {
  CommonAction,
  CommonState,
  commonReducer,
  initialState,
} from '../reducers/CommonReducer';

type CommonContextProps = {
  state: CommonState;
  commonDispatch: React.Dispatch<CommonAction>;
};

const CommonContext = createContext<CommonContextProps>({
  state: initialState,
  commonDispatch: () => initialState,
});

export function CommonContextProvider(props: React.PropsWithChildren<{}>) {
  const [state, commonDispatch] = useReducer(commonReducer, initialState);

  return (
    <CommonContext.Provider value={{ state, commonDispatch }} {...props} />
  );
}

export default function useCommonContext() {
  return useContext(CommonContext);
}
