export function objQueryConversion(data: any) {
  return Object.keys(data)
    .filter((k) => data[k] != null)
    .map((key) => `${key}=${data[key]}`)
    .join('&');
}

export function isEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

export function trimWithLowecase(value: string) {
  const modifyValue = value.toLowerCase().trim().replace(/ /g, '');
  return modifyValue;
}

/**
 * Function execute after `delay` milliseconds
 */
export function debounce(fn: (...args: any) => any, delay = 600): () => void {
  let timeOutID: any;

  return function debounced(...args: any) {
    if (timeOutID) {
      clearTimeout(timeOutID);
    }

    function executeOnLater() {
      fn(...args);
    }

    timeOutID = setTimeout(executeOnLater, delay);
  };
};
