import React, { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';

import Assignment from '../../assets/images/Assignment number.svg';
import Calander from '../../assets/images/Calander.svg';
import {
  TextInput,
  Button,
  SelectDropdown,
  RadioInput,
  StatusCard,
  TextArea,
} from '../../components';
import useCommonContext from '../../context/provider/CommonContext';
import { IUserInfo } from '../../model/Types';
import { getUserInfo, profileUpdate } from '../../services/api/AuthAPI';
import validationErrors from '../../services/ValidationSchema';
import { Layout } from '../layout/Layout';
import {
  ethinicityOptions,
  employmentOptions,
  jobRoleOptions,
  sduOptions,
  genderOptions,
} from './Options';

interface ProfileProps {
  user: IUserInfo;
}

function calculateAge(birthDate, otherDate) {
  birthDate = new Date(birthDate);
  otherDate = new Date(otherDate);
  let years = (otherDate.getFullYear() - birthDate.getFullYear());
  if (otherDate.getMonth() < birthDate.getMonth() ||
    otherDate.getMonth() === birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
    years -= 1;
  }
  return years;
}


// Validation Yup Schema for Email change
const validationSchemaProfile = Yup.object({
  pf_email: Yup.string()
    .email(validationErrors[1002])
    .required(validationErrors[1001]),
  pf_forename: Yup.string()
    .trim()
    .min(3, validationErrors[1020])
    .max(250, validationErrors[1017])
    // .matches(/^[a-zA-Z ]*$/, validationErrors[1015])
    .required(validationErrors[1001]),
  pf_surname: Yup.string().nullable()
    .trim()
    .min(1, validationErrors[1021])
    .max(250, validationErrors[1017])
    // .matches(/^[a-zA-Z ]*$/, validationErrors[1015])
    .required(validationErrors[1001]),
  pf_nhs_number: Yup.number().nullable()
    // .max(10, 'Must be less than 10 characters')
    // .min(10, 'Must be more than 10 characters')
    .test('maxDigits', validationErrors[1003], (number) => {
      if (!number || number === undefined || String(number).length === 0) {
        return true;
      }
      return String(number).length === 10;
    })
    .typeError(validationErrors[1013]),
  pf_assignment_number: Yup.string().trim().required(validationErrors[1001]),
  pf_postcode: Yup.string().trim().required(validationErrors[1001]),
  pf_address: Yup.string().trim().required(validationErrors[1001]),
  pf_mobile_no: Yup.string().trim().required(validationErrors[1001]),
  pf_gender: Yup.string().trim().required(validationErrors[1001]),
  pf_gender_other: Yup.string().trim().when('pf_gender', {
    is: (gender) => gender === 'other',
    then: Yup.string().required(validationErrors[1001]),
    otherwise: Yup.string().trim()
  }),
  pf_ethnicity: Yup.string().trim().required(validationErrors[1001]),
  pf_job_role: Yup.string().trim().required(validationErrors[1001]),
  pf_job_role_other: Yup.string().trim().when('pf_job_role', {
    is: (jobRole) => jobRole === 'other',
    then: Yup.string().required(validationErrors[1001]),
    otherwise: Yup.string().trim()
  }),
  pf_employment_status: Yup.string().trim().required(validationErrors[1001]),
  pf_sdu: Yup.string().trim().required(validationErrors[1001]),
  pf_work_area: Yup.string().trim().required(validationErrors[1001]),
  // pf_date_of_birth: Yup.date().required(validationErrors[1001]).nullable().typeError('Invalid Date')
  pf_date_of_birth: Yup.date().required(validationErrors[1001]).nullable().typeError('Invalid Date').test(
    "DOB",
    "Minimum age must be 16",
    value => {
      return calculateAge(moment(value).format('MM/DD/YYYY'), moment().format('MM/DD/YYYY')) >= 16;
    }
  )
    .test(
      "DOB",
      "Maximum age must be 80",
      value => {
        return calculateAge(moment(value).format('MM/DD/YYYY'), moment().format('MM/DD/YYYY')) <= 80;
      }
    )
});


export const Profile: React.FC<ProfileProps> = () => {
  const { commonDispatch } = useCommonContext();
  const [statusCard, setStatusCard] = useState({
    type: 'success',
    message: '',
  });
  const [user, setUser] = useState<IUserInfo | null>(null)

  async function getProfile() {
    const userInfo = await getUserInfo();
    if (userInfo) {
      setUser(userInfo.result.userData)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      commonDispatch({ type: 'UNSET_LOADING' });
    }, 2000);
    getProfile();
  }, [])

  const clearError = useCallback(() => {
    setTimeout(() => {
      setStatusCard({
        type: 'success',
        message: '',
      });
    }, 5000);
  }, []);
  function removeSpace(values) {
    console.log("pf_postcode", values);

    if (values) {
      // Remove extra white space in between words and beginning / end,
      return values.replace(/ +(?= )/g, '').toUpperCase();
      // return values.split(' ').join('').toUpperCase();
    }
  }
  const formik = useFormik({
    initialValues: {
      pf_forename: '',
      pf_surname: '',
      pf_nhs_number: '',
      pf_email: '',
      pf_date_of_birth: null,
      pf_assignment_number: '',
      pf_postcode: '',
      pf_address: '',
      pf_mobile_no: '',
      pf_gender: '',
      pf_gender_other: '',
      pf_ethnicity: '',
      pf_job_role: '',
      pf_job_role_other: '',
      pf_employment_status: '',
      pf_sdu: '',
      pf_work_area: '',
    },
    validationSchema: validationSchemaProfile,
    async onSubmit(values) {
      console.log("pf_date_of_birth...", values.pf_date_of_birth);

      try {
        commonDispatch({ type: 'SET_LOADING' });
        if (values.pf_gender === 'other') {
          values.pf_gender = `${values.pf_gender}-${values.pf_gender_other}`
          if (values.pf_gender.split('-') && values.pf_gender.split('-').length) {
            formik.setFieldValue('pf_gender', values.pf_gender.split('-')[0]);
            formik.setFieldValue('pf_gender_other', values.pf_gender.split('-')[1]);
          }
        } else {
          values.pf_gender_other = '';
          formik.setFieldValue('pf_gender_other', '');
        }

        if (values.pf_job_role === 'other') {
          values.pf_job_role = `${values.pf_job_role}-${values.pf_job_role_other}`
          if (values.pf_job_role.split('-') && values.pf_job_role.split('-').length) {
            formik.setFieldValue('pf_job_role', values.pf_job_role.split('-')[0]);
            formik.setFieldValue('pf_job_role_other', values.pf_job_role.split('-')[1]);
          }
        } else {
          values.pf_job_role_other = '';
          formik.setFieldValue('pf_job_role_other', '');
        }
        if (values.pf_postcode) {
          values.pf_postcode = removeSpace(values.pf_postcode);
          formik.setFieldValue('pf_postcode', values.pf_postcode);
        }

        console.log("values...", values);
        const profileUpdateStatus = await profileUpdate(values);

        console.log("profileUpdateStatus...", profileUpdateStatus);

        user.forename = values.pf_forename;
        if (profileUpdateStatus) {
          localStorage.setItem('name', `${values.pf_forename} ${values.pf_surname}`)
          setStatusCard({
            type: 'success',
            message: 'Your profile is updated.',
          });
          commonDispatch({ type: 'UNSET_LOADING' });
        } else {
          console.log("profileUpdateStatus.2..");

          commonDispatch({ type: 'UNSET_LOADING' });
          setStatusCard({
            type: 'danger',
            message: "Your profile is not updated. Please try again!",
          });
        }
        clearError();
      } catch (error) {
        console.log("profileUpdateStatus.3..");
        commonDispatch({ type: 'UNSET_LOADING' });
        console.log(error);
        setStatusCard({
          type: 'danger',
          message: error.message,
        });
        clearError();
      }
    },
  });

  useEffect(() => {
    if (user) {
      formik.setFieldValue('pf_forename', user.forename);
      formik.setFieldValue('pf_surname', user.surname);
      formik.setFieldValue('pf_nhs_number', user.nhs_number);
      formik.setFieldValue('pf_email', user.email);
      if (user.assignment_number) {
        formik.setFieldValue('pf_assignment_number', user.assignment_number);
      }
      if (user.postcode) {
        formik.setFieldValue('pf_postcode', user.postcode);
      }
      if (user.address) {
        formik.setFieldValue('pf_address', user.address);
      }
      if (user.date_of_birth) {
        formik.setFieldValue('pf_date_of_birth', moment(user.date_of_birth).format('MM/DD/YYYY'));
      }
      if (user.mobile_no) {
        formik.setFieldValue('pf_mobile_no', user.mobile_no);
      }
      if (user.gender) {
        if (user.gender.split('-') && user.gender.split('-').length) {
          formik.setFieldValue('pf_gender', user.gender.split('-')[0]);
          formik.setFieldValue('pf_gender_other', user.gender.split('-')[1]);
        } else {
          formik.setFieldValue('pf_gender', user.gender);
        }

      }
      if (user.ethnicity) {
        formik.setFieldValue('pf_ethnicity', user.ethnicity);
      }
      if (user.job_role) {
        if (user.job_role.split('-') && user.job_role.split('-').length) {
          formik.setFieldValue('pf_job_role', user.job_role.split('-')[0]);
          formik.setFieldValue('pf_job_role_other', user.job_role.split('-')[1]);
        } else {
          formik.setFieldValue('pf_job_role', user.job_role);
        }
      }
      if (user.employment_status) {
        formik.setFieldValue('pf_employment_status', user.employment_status);
      }
      if (user.work_area) {
        formik.setFieldValue('pf_work_area', user.work_area);
      }
      if (user.sdu) {
        formik.setFieldValue('pf_sdu', user.sdu);
      }
    }
  }, [user]);


  return (
    <div>

      <Layout nav>

        <div className="card py-5 card-padding my-md-5 my-0">


          <h2 className="text-left mb-4 fw-bold">{localStorage.getItem('name')} profile</h2>
          <div className="p-md-5 p-3 login-card">

            <form onSubmit={formik.handleSubmit}>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <TextInput
                      label="Forename"
                      placeholder=""
                      name="pf_forename"
                      type="text"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      values={formik.values.pf_forename}
                      errors={
                        formik.touched.pf_forename && formik.errors.pf_forename
                          ? formik.errors.pf_forename
                          : null
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <TextInput
                      label="Surname"
                      placeholder=""
                      name="pf_surname"
                      type="text"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      values={formik.values.pf_surname}
                      errors={
                        formik.touched.pf_surname && formik.errors.pf_surname
                          ? formik.errors.pf_surname
                          : null
                      }
                    />
                  </div>
                </div>

                <div className="h-5" />
                <TextInput
                  label="Assignment number (found on your payslip and on ESR - please see the image below for reference)"
                  placeholder=""
                  name="pf_assignment_number"
                  type="text"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.pf_assignment_number}
                  errors={
                    formik.touched.pf_assignment_number &&
                      formik.errors.pf_assignment_number
                      ? formik.errors.pf_assignment_number
                      : null
                  }
                />
                <div className="h-5" />
                <img src={Assignment} alt="" height="200px" />
                <div className="h-5" />
                <TextInput
                  label="NHS number (if known)"
                  placeholder=""
                  name="pf_nhs_number"
                  type="text"
                  required={false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.pf_nhs_number}
                  errors={
                    formik.touched.pf_nhs_number && formik.errors.pf_nhs_number
                      ? formik.errors.pf_nhs_number
                      : null
                  }
                />
                <div className="h-5" />
                <TextInput
                  label="Postcode"
                  placeholder=""
                  name="pf_postcode"
                  type="text"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.pf_postcode}
                  errors={
                    formik.touched.pf_postcode && formik.errors.pf_postcode
                      ? formik.errors.pf_postcode
                      : null
                  }
                />
                <div className="h-5" />
                {/* <TextInput
                  label="Address"
                  placeholder=""
                  name="pf_address"
                  type="text"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.pf_address}
                  errors={
                    formik.touched.pf_address && formik.errors.pf_address
                      ? formik.errors.pf_address
                      : null
                  }
                /> */}


                <TextArea
                  label="Address (first line, second line, and city/town)"
                  placeholder=""
                  required
                  name="pf_address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.pf_address}
                  errors={
                    formik.touched.pf_address && formik.errors.pf_address
                      ? formik.errors.pf_address
                      : null
                  }
                />

                <div className="h-5" />
                <TextInput
                  label="Mobile number"
                  placeholder=""
                  name="pf_mobile_no"
                  type="text"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.pf_mobile_no}
                  errors={
                    formik.touched.pf_mobile_no && formik.errors.pf_mobile_no
                      ? formik.errors.pf_mobile_no
                      : null
                  }
                />
                <div className="h-5" />

                <TextInput
                  label="Email address"
                  placeholder=""
                  name="pf_email"
                  type="text"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.pf_email}
                  errors={
                    formik.touched.pf_email && formik.errors.pf_email
                      ? formik.errors.pf_email
                      : null
                  }
                />
                <div className="h-5" />
                <p className="font-size mb-2">Gender <span className="star">*</span></p>

                {genderOptions.map((gender: any) => (
                  <RadioInput
                    type="radio"
                    label={gender.title}
                    name="pf_gender"
                    value={gender.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.pf_gender === gender.value && true}
                  />
                ))}
                {formik.touched.pf_gender && formik.errors.pf_gender ? (
                  <div className="text-danger form-error-msg">
                    {formik.errors.pf_gender}
                  </div>
                ) : null}
                {formik.values.pf_gender === 'other' && (
                  <>
                    <div className="h-5" />
                    <TextInput
                      label="Others (please specify)"
                      placeholder=""
                      name="pf_gender_other"
                      type="text"
                      required={formik.values.pf_gender === 'other'}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      values={formik.values.pf_gender_other}
                      errors={
                        formik.touched.pf_gender_other && formik.errors.pf_gender_other
                          ? formik.errors.pf_gender_other
                          : null
                      }
                    />
                  </>
                )}
                <div className="h-5" />

                <SelectDropdown
                  type="text"
                  required
                  name="pf_ethnicity"
                  label="Ethnicity"
                  placeholder=""
                  value={formik.values.pf_ethnicity}
                  onChange={async (value: any) => {
                    if (value) {
                      formik.setFieldValue('pf_ethnicity', value.value);
                    }
                  }}
                  errors={
                    formik.touched.pf_ethnicity && formik.errors.pf_ethnicity
                      ? formik.errors.pf_ethnicity
                      : null
                  }
                  optionsArray={ethinicityOptions}
                />
                <div className="h-5" />

                <p className="input-label">Date of birth <span className="star">*</span></p>
                <div className='pos-relative'>
                  <DatePicker
                    selected={formik.values.pf_date_of_birth ? new Date(formik.values.pf_date_of_birth) : null}
                    dateFormat="dd-MM-yyyy"
                    className="form-control datepicker-h w-100"
                    name="pf_date_of_birth"
                    dropdownMode="select"
                    showYearDropdown
                    placeholderText="dd/mm/yyyy"
                    showMonthDropdown
                    maxDate={new Date()}
                    onChange={(date: any) => {
                      if (date) {
                        formik.setFieldValue('pf_date_of_birth', moment(date).format('YYYY-MM-DD'))
                      }
                    }}
                  />
                  <div className='pos-calender'>
                    <img src={Calander} alt="" />

                  </div>
                </div>
                {formik.touched.pf_date_of_birth && formik.errors.pf_date_of_birth && <p className={formik.touched.pf_date_of_birth && formik.errors.pf_date_of_birth ? 'date-error text-danger' : ''}>
                  {formik.touched.pf_date_of_birth && formik.errors.pf_date_of_birth
                    ? formik.errors.pf_date_of_birth
                    : null}</p>
                }

                <div className="h-5" />

                <SelectDropdown
                  type="text"
                  required
                  name="pf_employment_status"
                  label="Employment status"
                  placeholder=""
                  value={formik.values.pf_employment_status}
                  onChange={async (value: any) => {
                    if (value) {
                      formik.setFieldValue('pf_employment_status', value.value);
                    }
                  }}
                  errors={
                    formik.touched.pf_employment_status &&
                      formik.errors.pf_employment_status
                      ? formik.errors.pf_employment_status
                      : null
                  }
                  optionsArray={employmentOptions}
                />
                <div className="h-5" />

                <SelectDropdown
                  required
                  type="text"
                  name="pf_job_role"
                  label="Job role"
                  placeholder=""
                  value={formik.values.pf_job_role}
                  onChange={async (value: any) => {
                    if (value) {
                      formik.setFieldValue('pf_job_role', value.value);
                    }
                  }}
                  errors={
                    formik.touched.pf_job_role && formik.errors.pf_job_role
                      ? formik.errors.pf_job_role
                      : null
                  }
                  optionsArray={jobRoleOptions}
                />

                {formik.values.pf_job_role === 'other' && (
                  <>
                    <div className="h-5" />
                    <TextInput
                      label="Others (please specify)"
                      placeholder=""
                      name="pf_job_role_other"
                      type="text"
                      required={formik.values.pf_job_role === 'other'}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      values={formik.values.pf_job_role_other}
                      errors={
                        formik.touched.pf_job_role_other && formik.errors.pf_job_role_other
                          ? formik.errors.pf_job_role_other
                          : null
                      }
                    />
                  </>
                )}
                <div className="h-5" />

                <SelectDropdown
                  type="text"
                  required
                  name="pf_sdu"
                  label="SDU"
                  placeholder=""
                  value={formik.values.pf_sdu}
                  onChange={async (value: any) => {
                    if (value) {
                      formik.setFieldValue('pf_sdu', value.value);
                    }
                  }}
                  errors={
                    formik.touched.pf_sdu && formik.errors.pf_sdu
                      ? formik.errors.pf_sdu
                      : null
                  }
                  optionsArray={sduOptions}
                />
                <div className="h-5" />

                <TextInput
                  label="Which department or ward do you work in?"
                  placeholder=""
                  name="pf_work_area"
                  type="text"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  values={formik.values.pf_work_area}
                  errors={
                    formik.touched.pf_work_area && formik.errors.pf_work_area
                      ? formik.errors.pf_work_area
                      : null
                  }
                />
                <div className="h-5" />
                <div className="h-5" />
                {statusCard && statusCard.message && (
                  <StatusCard
                    type={statusCard.type}
                    message={statusCard.message}
                  />
                )}
                <div className="text-center">
                  <Button
                    btntype="submit"
                    text="Save"
                    addClass="primary-btn px-4"
                  />
                </div>
                <div className="h-5" />
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </div>
  );
};
